import React, { useState, useEffect, useRef } from 'react';
import {Col, Button, Modal, Form, Row,Tab,Nav , Table } from "react-bootstrap";
import Moment from 'react-moment';
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { audio } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ntpd_arrow from '../../../assets/images/icons/ntpd_arrow.png';
import mic_arrow from '../../../assets/images/icons/mic_arrow.png';
import video_arrow from '../../../assets/images/icons/video_arrow.png';
import rd_trash from '../../../assets/images/icons/rd_trash.png';
import video from '../../../assets/images/icons/video.png';
import member1 from '../../../assets/images/member1.png';

import useAssignmentsQueue from '../../../hooks/useAssignmentsQueue';
import PAGINATION from '../../../components/common/pagination';

import { BACKEND_DOMAIN } from '../../../config/variables';
import { PATCH } from '../../../utils/axios.util';

function SuperAdminInterpretation(props) {

    const { register, handleSubmit, clearErrors, formState: { errors } } = useForm();
    const {
        register: register2,
        clearErrors: clearErrors2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const assignments = useAssignmentsQueue()
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const itemsPerPage = 10
    const [ requestedOffset, setRequestedOffset ] = useState(0);
    const [ progressOffset, setProgressOffset ] = useState(0);
    const [ reviewOffset, setReviewOffset ] = useState(0);
    const [ completedOffset, setCompletedOffset ] = useState(0);
    const [ currentRequestedItems, setCurrentRequestedItems ] = useState([])
    const [ currentProgressItems, setCurrentProgressItems ] = useState([])
    const [ currentReviewItems, setCurrentReviewItems ] = useState([])
    const [ currentCompletedItems, setCurrentCompletedItems ] = useState([])
    const [ requestedPageCount, setRequestedPageCount ] = useState(0);
    const [ progressPageCount, setProgressPageCount ] = useState(0);
    const [ reviewPageCount, setReviewPageCount ] = useState(0);
    const [ completedPageCount, setCompletedPageCount ] = useState(0);
    const [ activePageNumber, setActivePageNumber ] = useState(''); 
    const [showDiscription, setShowDiscription] = useState([])
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [approveModalShow, setApproveModalShow] = useState(false);
    const [cancelModalShow, setCancelModalShow] = useState(false);
    const [disApproveModalShow, setDisApproveModalShow] = useState(false);
    const [approveDescriptionModalShow, setApproveDescriptionModalShow] = useState(false);
    const [disapproveDescriptionModalShow, setDisApproveDescriptionModalShow] = useState(false);
    const [descriptionModal, setdescriptionModal] = useState(false)
    const [descriptionValue, setdescriptionValue] = useState('')
    const [approveId, setApproveId] = useState('');
    const [cancelId, setCancelId] = useState('');
    const [deleteId, setDeleteId] = useState('');
    const [disApproveId, setDisApproveId] = useState('');
    const handleApproveModalClose = () => setApproveModalShow(false);
    const handleDisApproveModalClose = () => setDisApproveModalShow(false);
    const handleDeleteModalClose = () => setDeleteModalShow(false);
    const handleCancelModalClose = () => setCancelModalShow(false);
    const handleApproveDescriptionModalClose = () => setApproveDescriptionModalShow(false);
    const handleDisApproveDescriptionModalClose = () => setDisApproveDescriptionModalShow(false);

    var isAssignmentsListingInitialized = useRef(true)

    const urlParams = new URLSearchParams(window.location.search);
    var urlTab = urlParams.get('tab')
    var urlPage = urlParams.get('page')
    let defaultTabValue = 'requested'
    let currentPageNumValue = 0
    let initialPageValue = 0

    if(urlTab == 'progress') {
        defaultTabValue = 'progress'
    }
    if(urlTab == 'review') {
        defaultTabValue = 'review'
    }
    if(urlTab == 'completed') {
        defaultTabValue = 'completed'
    }

    if(urlPage) {
        currentPageNumValue = Number(urlPage) - 1
        initialPageValue = Number(urlPage) - 1
    }

    const [ currentPageNum, setCurrentPageNum ] = useState(currentPageNumValue);
    const initialPageNum = initialPageValue;

    const [ defaultTab, setDefaultTab ] = useState(defaultTabValue);

    useEffect(() => {

        if(Object.keys(assignments).length > 0 && isAssignmentsListingInitialized.current) {

            const requestedEndOffset = requestedOffset + itemsPerPage;
            const progressEndOffset = progressOffset + itemsPerPage;
            const reviewEndOffset = reviewOffset + itemsPerPage;
            const completedEndOffset = completedOffset + itemsPerPage;
            let requestedChunk = []
            let progressChunk = []
            let reviewChunk = []
            let completedChunk = []
            let requestedPageCount = Math.ceil(assignments.list.requested.length / itemsPerPage);
            let progressPageCount = Math.ceil(assignments.list.progress.length / itemsPerPage);
            let reviewPageCount = Math.ceil(assignments.list.review.length / itemsPerPage);
            let completedPageCount = Math.ceil(assignments.list.completed.length / itemsPerPage);

            if(assignments.list.requested.length > 0) {
                requestedChunk = assignments.list.requested.slice(requestedOffset, requestedEndOffset)
            }
            if(assignments.list.progress.length > 0) {
                progressChunk = assignments.list.progress.slice(progressOffset, progressEndOffset)
            }
            if(assignments.list.review.length > 0) {
                reviewChunk = assignments.list.review.slice(reviewOffset, reviewEndOffset)
            }
            if(assignments.list.completed.length > 0) {
                completedChunk = assignments.list.completed.slice(completedOffset, completedEndOffset)
            }

            setRequestedPageCount(requestedPageCount)
            setProgressPageCount(progressPageCount)
            setReviewPageCount(reviewPageCount)
            setCompletedPageCount(completedPageCount)
            setCurrentRequestedItems(requestedChunk)
            setCurrentProgressItems(progressChunk)
            setCurrentReviewItems(reviewChunk)
            setCurrentCompletedItems(completedChunk)
            isAssignmentsListingInitialized.current = false

        }

    },[ assignments, currentRequestedItems, currentProgressItems, currentReviewItems, currentCompletedItems, requestedOffset, progressOffset, reviewOffset, completedOffset, itemsPerPage ]);

    const handlePageClick = (event, currentTab) => {

        let items = []

        if(currentTab == 'requested') {
            items = assignments.list.requested
        }
        if(currentTab == 'progress') {
            items = assignments.list.progress
        }
        if(currentTab == 'review') {
            items = assignments.list.review
        }
        if(currentTab == 'completed') {
            items = assignments.list.completed
        }
        
        const newOffset = (event.selected * itemsPerPage) % items.length;
        
        const endOffset = newOffset + itemsPerPage;
        let chunk = items.slice(newOffset, endOffset)
        const pageCount = Math.ceil(items.length / itemsPerPage);
        
        if(currentTab == 'requested') {
            setRequestedPageCount(pageCount)
            setCurrentRequestedItems(chunk)
            setRequestedOffset(newOffset);
        }
        if(currentTab == 'progress') {
            setProgressPageCount(pageCount)
            setCurrentProgressItems(chunk)
            setProgressOffset(newOffset);
        }
        if(currentTab == 'review') {
            setReviewPageCount(pageCount)
            setCurrentReviewItems(chunk)  
            setReviewOffset(newOffset);
        }
        if(currentTab == 'completed') {
            setCompletedPageCount(pageCount)
            setCurrentCompletedItems(chunk)
            setCompletedOffset(newOffset);
        }
            
    };

    const viewAssignment = (elem) => {

        if(elem.status == 'pending' || elem.status == 'progress' || elem.status == 'interrupted' || elem.status == 'error') {
            if(elem.type == 'audio') {
                dispatch(audio(elem.original_file_path, elem.title, elem.category_title, elem.sub_category_title))
            }else if(elem.type == 'document') {
                window.open(elem.original_file_path, '_blank');
            }else {
                navigate('/assignments/' + elem.assignment_id)
            }
        }else {

            navigate('/assignments/' + elem.assignment_id)
        }
        
    }

    const handleApproveModal = (queue_id) => {

        setApproveId(queue_id)
        setApproveModalShow(true)
    }

    const handleDisApproveModal = (queue_id) => {

        setDisApproveId(queue_id)
        setDisApproveModalShow(true)
    }

    const openApproveDescriptionModal = () => {
        clearErrors('approve_message')
        setApproveModalShow(false)
        setApproveDescriptionModalShow(true)
    }
    
    const openDisApproveDescriptionModal = () => {
        clearErrors2('disapprove_message')
        setDisApproveModalShow(false)
        setDisApproveDescriptionModalShow(true)
    }

    const closeDescriptionModal = () => {
        setdescriptionModal(false)
        setdescriptionValue("")
    }

    const openDescriptionModal = (data) => {
        if(data?.length > 0){
            setdescriptionValue(data)
            setdescriptionModal(true)
        }
    }
    
    const approve = (data) => {

        let queue_id = data

        let payload = {
            url : `${BACKEND_DOMAIN}/assignments/approve`,
            data: {
                queue_id
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setSearchParams({tab: 'completed'});
            navigate(0)

        }).catch(err => {
            
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
        
    }
    
    const disApprove = (data) => {

        let queue_id = disApproveId

        let payload = {
            url : `${BACKEND_DOMAIN}/assignments/dis-approve`,
            data: {
                queue_id,
                disapprove_message: data.disapprove_message
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setSearchParams({tab: 'progress'});
            navigate(0)
            setShowDiscription(true)

        }).catch(err => {
            
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
        
    }

    const changeTab = (tab) => {
        setSearchParams({ tab });
        setCurrentPageNum(0)
    }

    const assignPage = (elem) => {
        let activeItemSelector = `#fdgdfg-tabpane-requested ul li.page-item.active a`
        let activePageNumber = document.querySelector(activeItemSelector).innerText

        setActivePageNumber(activePageNumber)

        navigate(`/assignments/${elem.assignment_id}/assign/interpreter?tab=requested&page=${activePageNumber}`)
    }

    const cancelModal = (queue_id) => {

        setCancelId(queue_id)
        setCancelModalShow(true)
    }

    const handleDeleteModalShow = (queue_id) => {

        setDeleteId(queue_id)
        setDeleteModalShow(true)
    }

    const cancelAssignment = (queue_id) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/assignments/cancel-progress`,
            data: {
                queue_id
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setSearchParams({tab: 'progress'});
            navigate(0)

        }).catch(err => {
            
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    const deleteAssignment = (queue_id) => {

        let payload = {
            url : `${BACKEND_DOMAIN}/assignments/delete-completed`,
            data: {
                queue_id
            }
        }

        PATCH(payload).then(res => {

            toast.success(res.data.message)
            setSearchParams({tab: 'completed'});
            navigate(0)

        }).catch(err => {

            setDeleteModalShow(false)
            
            // Session Logout Code
            if(err.response && err.response.status === 401) {
                toast.error('Session Expired! Logging out...', { autoClose: 2000 })
                setTimeout(() => {
                    window.location.reload();
                }, 1000)
            }

            if(err.response.status === 403) {
                toast.error(err.response.data.message)
            }
        })
    }

    return(
        <>        

            <h2 className="page_hdng">Assignments</h2>
            <Row className="mt-3">
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(assignments).length > 0 ? assignments.requested_count : 0}</h4>
                        <p className='text-capatilize'>requested</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(assignments).length > 0 ? assignments.progress_count : 0}</h4>
                        <p className='text-capatilize'>in progress</p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(assignments).length > 0 ? assignments.review_count : 0}</h4>
                        <p className='text-capatilize'>under Review </p>
                    </div>
                </Col>
                <Col sm="12" md="6" lg="2">
                    <div className="assignments_card">
                        <h4>{Object.keys(assignments).length > 0 ? assignments.completed_count : 0}</h4>
                        <p className='text-capatilize'>completed</p>
                    </div>
                </Col>
                <Col sm="12" md="6" className='col-lg-auto'>
                    <div className="assignments_card">
                        <div className='my-2 d-flex align-items-center justify-content-between' >
                            <div className='d-flex align-items-center'>
                                <span><img height={20} src={ntpd_arrow} /></span>
                                <span className='fs_14 fw_500 text_brand_color2 m-1'>{Object.keys(assignments).length > 0 ? assignments.formats.documents_count : 0}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span><img height={20} src={mic_arrow} /></span>
                                <span className='fs_14 fw_500 text_brand_color2 m-1'>{Object.keys(assignments).length > 0 ? assignments.formats.audios_count : 0}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span><img height={20} src={video_arrow} /></span>
                                <span className='fs_14 fw_500 text_brand_color2 m-1'>{Object.keys(assignments).length > 0 ? assignments.formats.videos_count : 0}</span>
                            </div>
                        </div>
                        <p className='text-capatilize'>Assignments Formats</p>
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col>
                <div className="fixed_tab">
                    <Tab.Container id="fdgdfg" defaultActiveKey={defaultTab}>
                        <Row>
                            <Col className='col-auto'>
                                <Nav variant="fixed_pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="requested" onClick={() => changeTab('requested')}>Requested</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="progress" onClick={() => changeTab('progress')}>In Progress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="review" onClick={() => changeTab('review')}>Under Review</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="completed" onClick={() => changeTab('completed')}>Completed</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>

                        <Tab.Content>
                            <Tab.Pane eventKey="requested">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th>Assignments Name</th>
                                            <th>Date & Time</th>
                                            <th colSpan={2} width="50">Coordinators</th>
                                            <th className='text-center'>Format</th>
                                            <th colSpan={2} width="50">Interpreter</th>
                                            <th>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(assignments).length > 0 && assignments.list.requested.length > 0 &&
                                            currentRequestedItems.map((elem, index) => {

                                                let durationSplit = elem.duration.split(':');
                                                let hours = parseInt(durationSplit[0])
                                                let mins = parseInt(durationSplit[1])
                                                let secs = parseInt(durationSplit[2])

                                                var duration = ''
                                                var assignmentIcon = ''

                                                if(hours > 0) {
                                                    duration += `${hours}h`
                                                }
                                                if(mins > 0) {
                                                    duration += ` ${mins}m`
                                                }
                                                if(secs > 0) {
                                                    duration += ` ${secs}s`
                                                }
                                                duration = duration.trim()

                                                if(elem.type == 'video') {
                                                    assignmentIcon = video_arrow
                                                }
                                                if(elem.type == 'audio') {
                                                    assignmentIcon = mic_arrow
                                                }
                                                if(elem.type == 'document') {
                                                    assignmentIcon = ntpd_arrow
                                                }
                                                
                                                return (
                                                    <tr key={elem.id}>
                                                    <td>
                                                        <h5>{elem.title}</h5>
                                                        <p>
                                                            <span className='text_white_color_half'>{elem.division_name},</span> 
                                                            <span className='text_brand_color2'>{elem.category_title}</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                        <span className='text_brand_color3'>
                                                            <Moment format="hh:mm A">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </span>
                                                    </td>
                                                    {elem.assigner && 
                                                        <>
                                                            <td width="50">
                                                                <img className='tbl_usr_img' src={elem.assigner.picture} />
                                                            </td>
                                                            <td>
                                                                <h5>{elem.assigner.full_name}</h5>
                                                            </td>
                                                        </>
                                                    }
                                                    {!elem.assigner && 
                                                        <td className='text-center'>
                                                            <h5>--</h5>
                                                        </td>
                                                    }
                                                    <td className='text-center'>
                                                        <img src={assignmentIcon} />
                                                    </td>
                                                    {elem.interpreter && 
                                                        <>
                                                            <td width="50">
                                                                <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                            </td>
                                                            <td>
                                                                <h5>{elem.interpreter.full_name}</h5>
                                                            </td>
                                                        </>
                                                    }
                                                    {!elem.interpreter && 
                                                        <>
                                                            <td className='text-center'>
                                                                <h5>--</h5>
                                                            </td>
                                                            <td className='text-center'>
                                                            </td>
                                                        </>
                                                    }
                                                    <td className='text_brand_color6 text-capitalize'>{elem.status}</td>
                                                    <td className='text-center'>
                                                        { elem.interpreter_id && 
                                                            <>
                                                                <Button variant='success' size='sm' disabled={true} >Interpreter Assigned</Button>&nbsp;|&nbsp;
                                                            </>
                                                        }
                                                        { !elem.interpreter_id &&
                                                            <>
                                                                <Button variant='success' size='sm' onClick={() => assignPage(elem)} >Assign Interpreter</Button>&nbsp;|&nbsp;
                                                            </>
                                                        }
                                                        <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>
                                                    </td>
                                                </tr>
                                                )
                                            })
                                        }
                                        {Object.keys(assignments).length > 0 && assignments.list.requested.length === 0 &&
                                            <tr className="text-center">
                                                <td colSpan="10" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                { Object.keys(assignments).length > 0 && assignments.list.requested.length > 0 &&
                                    <PAGINATION allItems={assignments.list.requested} chunkedItems={currentRequestedItems} handlePageClick={event => handlePageClick(event, 'requested')} pageCount={requestedPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="progress">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th>Assignments Name</th>
                                            <th>Date & Time</th>
                                            <th colSpan={2} width="50">Coordinators</th>
                                            <th>Format & Description</th>
                                            <th className='text-center'>AWS Job Id</th>
                                            <th className='text-center'>AWS Username</th>
                                            <th colSpan={2} width="50">Interpreter</th>
                                            <th className='text-center' >Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(assignments).length > 0 && assignments.list.progress.length > 0 &&
                                            currentProgressItems.map((elem, index) => {

                                                let durationSplit = elem.duration.split(':');
                                                let hours = parseInt(durationSplit[0])
                                                let mins = parseInt(durationSplit[1])
                                                let secs = parseInt(durationSplit[2])

                                                var duration = ''
                                                var assignmentIcon = ''

                                                if(hours > 0) {
                                                    duration += `${hours}h`
                                                }
                                                if(mins > 0) {
                                                    duration += ` ${mins}m`
                                                }
                                                if(secs > 0) {
                                                    duration += ` ${secs}s`
                                                }
                                                duration = duration.trim()

                                                if(elem.type == 'video') {
                                                    assignmentIcon = video_arrow
                                                }
                                                if(elem.type == 'audio') {
                                                    assignmentIcon = mic_arrow
                                                }
                                                if(elem.type == 'document') {
                                                    assignmentIcon = ntpd_arrow
                                                }

                                                let fontColor = 'text_brand_color3'

                                                if(elem.status == 'error' || elem.status == 'disapproved') {
                                                    fontColor = 'text_brand_color7'
                                                }

                                                if(elem.status == 'interrupted') {
                                                    fontColor = 'text_brand_color8'
                                                }

                                                if(elem.status == 'converted') {
                                                    fontColor = 'text_brand_color2'
                                                }

                                                return (
                                                <tr key={elem.id}>
                                                    <td>
                                                        <h5>{elem.title}</h5>
                                                        <p>
                                                            <span className='text_white_color_half'>{elem.division_name},</span> 
                                                            <span className='text_brand_color2'>{elem.category_title}</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <h5>
                                                            <Moment format="MM/DD/YY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                        <span className='text_brand_color3'>
                                                            <Moment format="hh:mm A">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </span>
                                                    </td>
                                                    {elem.assigner && 
                                                        <>
                                                            <td width="50">
                                                                <img className='tbl_usr_img' src={elem.assigner.picture} />
                                                            </td>
                                                            <td>
                                                                <h5>{elem.assigner.full_name}</h5>
                                                            </td>
                                                        </>
                                                    }
                                                    {!elem.assigner && 
                                                        <td className='text-center'>
                                                            <h5>--</h5>
                                                        </td>
                                                    }
                                                    <td className='text-center '>
                                                        <a className='cursor-pointer '><img src={assignmentIcon}  onClick={() => openDescriptionModal(elem.description)}/></a>
                                                    </td>
                                                    <td className='text-center'>
                                                        <h5>{elem.aws_job_id ? elem.aws_job_id : '--' }</h5>
                                                    </td>
                                                    <td className='text-center'>
                                                        <h5>{elem.aws_username ? elem.aws_username : '--' }</h5>
                                                    </td>
                                                    {elem.interpreter && 
                                                        <>
                                                            <td width="50">
                                                                <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                            </td>
                                                            <td>
                                                                <h5>{elem.interpreter.full_name}</h5>
                                                            </td>
                                                        </>
                                                    }
                                                    {!elem.interpreter && 
                                                        <>
                                                            <td className='text-center'>
                                                                <h5>--</h5>
                                                            </td>
                                                            <td className='text-center'>
                                                            </td>
                                                        </>
                                                    }
                                                    {/* <td className='text-center'>
                                                        <h5>{elem.status == 'error' ? elem.original_file_path : '--' }</h5>
                                                    </td> */}
                                                    
                                                    <td className={`${fontColor} text-capitalize d-flex align-center py-4`}>
                                                       
                                                            
                                                        <h5 className='lh-lg'>{elem.status}</h5>
                                                        </td>
                                                    { elem.status != 'error' &&
                                                        <td className='text-center'>
                                                            <Button variant='dark'  size='sm' onClick={() => viewAssignment(elem)} >View</Button>&nbsp;
                                                            <Button variant='success' size='sm' onClick={() => cancelModal(elem.id)} style={{ backgroundColor: '#E34D4D', borderColor: '#E34D4D'}} >Cancel</Button>
                                                        </td>
                                                    }
                                                </tr>
                                                )
                                            })
                                        }
                                        {Object.keys(assignments).length > 0 && assignments.list.progress.length === 0 &&
                                            <tr className="text-center">
                                                <td colSpan="11" className="fs-4">
                                                    No Records Found!
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </Table>
                                { Object.keys(assignments).length > 0 && assignments.list.progress.length > 0 &&
                                    <PAGINATION allItems={assignments.list.progress} chunkedItems={currentProgressItems} handlePageClick={event => handlePageClick(event, 'progress')} pageCount={progressPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="review">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th>Assignments Name</th>
                                            <th className='text-center'>Date & Time</th>
                                            <th className='text-center'>Duration </th>
                                            <th className='text-center'>Format</th>
                                            <th colSpan={2} width="50">Interpreter</th>
                                            <th className='text-center'>Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Object.keys(assignments).length > 0 && assignments.list.review.length > 0 &&
                                        currentReviewItems.map((elem, index) => {

                                            let durationSplit = elem.duration.split(':');
                                            let hours = parseInt(durationSplit[0])
                                            let mins = parseInt(durationSplit[1])
                                            let secs = parseInt(durationSplit[2])

                                            var duration = ''
                                            var assignmentIcon = ''

                                            if(hours > 0) {
                                                duration += `${hours}h`
                                            }
                                            if(mins > 0) {
                                                duration += ` ${mins}m`
                                            }
                                            if(secs > 0) {
                                                duration += ` ${secs}s`
                                            }
                                            duration = duration.trim()

                                            if(elem.type == 'video') {
                                                assignmentIcon = video_arrow
                                            }
                                            if(elem.type == 'audio') {
                                                assignmentIcon = mic_arrow
                                            }
                                            if(elem.type == 'document') {
                                                assignmentIcon = ntpd_arrow
                                            }

                                            return (
                                                <tr key={elem.id}>
                                                    <td>
                                                        <h5>{elem.title}</h5>
                                                        <p>
                                                            <span className='text_white_color_half'>{elem.division_name},</span> 
                                                            <span className='text_brand_color2'>{elem.category_title}</span>
                                                        </p>
                                                    </td>
                                                    <td className='text-center'>
                                                        <h5>
                                                            <Moment format="MM/DD/YY">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </h5>
                                                        {/* <span className='text_brand_color3'>09:00AM</span> */}
                                                        <span className='text_brand_color3'>
                                                            <Moment format="hh:mm A">
                                                                {elem.created_at}
                                                            </Moment>
                                                        </span>
                                                    </td>
                                                    {/* <td className='text-center'>8h30s</td> */}
                                                    <td className='text-center'>
                                                        <h5>{duration}</h5>
                                                    </td>
                                                    <td className='text-center'>
                                                        <img src={assignmentIcon} />
                                                    </td>
                                                    {elem.interpreter && 
                                                        <>
                                                            <td width="50">
                                                                <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                            </td>
                                                            <td>
                                                                <h5>{elem.interpreter.full_name}</h5>
                                                            </td>
                                                        </>
                                                    }
                                                    {!elem.interpreter && 
                                                        <>
                                                            <td className='text-center'>
                                                                <h5>--</h5>
                                                            </td>
                                                            <td className='text-center'>
                                                            </td>
                                                        </>
                                                    }
                                                    <td className='text-center text_brand_color3 text-capitalize'>{elem.status}</td>
                                                    <td className='text-center'>
                                                        <Button variant='success' size='sm' onClick={() => approve(elem.id)} >Approve</Button>&nbsp;
                                                        
                                                        <Button variant='success'className='disapprove' size='sm' onClick={() => handleDisApproveModal(elem.id)}  >Disapprove</Button>&nbsp;|&nbsp;
                                                        <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {Object.keys(assignments).length > 0 && assignments.list.review.length === 0 &&
                                        <tr className="text-center">
                                            <td colSpan="10" className="fs-4">
                                                No Records Found!
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </Table>
                                { Object.keys(assignments).length > 0 && assignments.list.review.length > 0 &&
                                    <PAGINATION allItems={assignments.list.review} chunkedItems={currentReviewItems} handlePageClick={event => handlePageClick(event, 'review')} pageCount={reviewPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="completed">
                                <Table className="">
                                    <thead>
                                        <tr>
                                            <th>Assignments Name</th>
                                            <th>Date & Time</th>
                                            <th colSpan={2} width="50">Coordinators</th>
                                            <th className='text-center'>Format</th>
                                            <th colSpan={2} width="50">Interpreter</th>
                                            <th>Assigned</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(assignments).length > 0 && assignments.list.completed.length > 0 &&
                                            currentCompletedItems.map((elem, index) => {

                                                let durationSplit = elem.duration.split(':');
                                                let hours = parseInt(durationSplit[0])
                                                let mins = parseInt(durationSplit[1])
                                                let secs = parseInt(durationSplit[2])

                                                var duration = ''
                                                var assignmentIcon = ''

                                                if(hours > 0) {
                                                    duration += `${hours}h`
                                                }
                                                if(mins > 0) {
                                                    duration += `${mins}m`
                                                }
                                                if(secs > 0) {
                                                    duration += `${secs}s`
                                                }
                                                duration = duration.trim()

                                                if(elem.type == 'video') {
                                                    assignmentIcon = video_arrow
                                                }
                                                if(elem.type == 'audio') {
                                                    assignmentIcon = mic_arrow
                                                }
                                                if(elem.type == 'document') {
                                                    assignmentIcon = ntpd_arrow
                                                }

                                                return (
                                                    <tr key={elem.id}>
                                                        <td>
                                                            <h5>{elem.title}</h5>
                                                            <p>
                                                                <span className='text_white_color_half'>{elem.division_name},</span> 
                                                                <span className='text_brand_color2'>{elem.category_title}</span>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <h5>
                                                                <Moment format="MM/DD/YY">
                                                                    {elem.created_at}
                                                                </Moment>
                                                            </h5>
                                                            <span className='text_brand_color3'>
                                                                <Moment format="hh:mm A">
                                                                    {elem.created_at}
                                                                </Moment>
                                                            </span>
                                                        </td>
                                                        {elem.assigner && 
                                                            <>
                                                                <td width="50">
                                                                    <img className='tbl_usr_img' src={elem.assigner.picture} />
                                                                </td>
                                                                <td>
                                                                    <h5>{elem.assigner.full_name}</h5>
                                                                </td>
                                                            </>
                                                        }
                                                        {!elem.assigner && 
                                                            <td className='text-center'>
                                                                <h5>--</h5>
                                                            </td>
                                                        }
                                                        <td className='text-center'>
                                                            <img src={assignmentIcon} />
                                                        </td>
                                                        {elem.interpreter && 
                                                            <>
                                                                <td width="50">
                                                                    <img className='tbl_usr_img' src={elem.interpreter.picture} />
                                                                </td>
                                                                <td>
                                                                    <h5>{elem.interpreter.full_name}</h5>
                                                                </td>
                                                            </>
                                                        }
                                                        {!elem.interpreter && 
                                                            <>
                                                                <td className='text-center'>
                                                                    <h5>--</h5>
                                                                </td>
                                                                <td className='text-center'>
                                                                </td>
                                                            </>
                                                        }
                                                        <td>
                                                            { elem.assigned_users.length > 0 && 
                                                                <div className='tbl_assigned_users_img'>

                                                                    { elem.assigned_users.map((item, index) => {

                                                                            if(index <= 4) {
                                                                                return (
                                                                                    <img className='tbl_usr_img' src={item.picture_path} />
                                                                                )
                                                                            }
                                                                            
                                                                        })
                                                                    }
                                                                    {elem.assigned_users.length > 5 &&
                                                                        <span>
                                                                            {elem.assigned_users.length - 5}+
                                                                        </span>
                                                                    }
                                                                    
                                                                </div>
                                                            }
                                                            { !elem.assigned_users.length > 0 &&
                                                                <td className='text-center'>
                                                                    <h5>--</h5>
                                                                </td>
                                                            }
                                                        </td>
                                                        <td className='text-center'>
                                                            <Button variant='dark' size='sm' onClick={() => viewAssignment(elem)} >View</Button>&nbsp;|&nbsp;
                                                            <NavLink onClick={event => handleDeleteModalShow(elem.id)} >
                                                                <span className="badge badge_md">
                                                                    <img src={rd_trash} />
                                                                </span>
                                                            </NavLink>
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            {Object.keys(assignments).length > 0 && assignments.list.completed.length === 0 &&
                                                <tr className="text-center">
                                                    <td colSpan="10" className="fs-4">
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                            }
                                    </tbody>
                                </Table>
                                { Object.keys(assignments).length > 0 && assignments.list.completed.length > 0 &&
                                    <PAGINATION allItems={assignments.list.completed} chunkedItems={currentCompletedItems} handlePageClick={event => handlePageClick(event, 'completed')} pageCount={completedPageCount} currentPageNumber={currentPageNum}  initialPageNumber={initialPageNum} />
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                    </div>
                </Col>
            </Row>

            <Modal className="approve-modal" show={approveModalShow} onHide={handleApproveModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you wdsdvdsant to approve ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleApproveModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => openApproveDescriptionModal()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="approve-modal" show={disApproveModalShow} onHide={handleDisApproveModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you want to disapprove ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleDisApproveModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => openDisApproveDescriptionModal()}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="approve-modal" show={cancelModalShow} onHide={handleCancelModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you want to cancel ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleCancelModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => cancelAssignment(cancelId)}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="approve-modal" show={deleteModalShow} onHide={handleDeleteModalClose} backdrop="static" keyboard={false} centered>
                <Modal.Header className='justify-content-center'>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h4>Are you sure you want to delete ?</h4>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <div className="d-flex gap-3">
                        <Button variant="danger" onClick={handleDeleteModalClose} >NO</Button>
                        <Button variant="primary" onClick={() => deleteAssignment(deleteId)}>YES</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal className="approve-modal" show={approveDescriptionModalShow} onHide={handleApproveDescriptionModalClose} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>Add Description</Modal.Title>
                    </div>
                </Modal.Header>
                <Form onSubmit={handleSubmit(approve)} >
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
                            <Form.Control as="textarea" rows="5" name="approve_message" {...register('approve_message', { required: "This field is required" })} autoComplete="off" />

                            {errors.approve_message ?.message && <span className="error">{errors.approve_message ?.message}</span> }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="primary" type="submit" >Submit</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal className="approve-modal" show={disapproveDescriptionModalShow} onHide={handleDisApproveDescriptionModalClose} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>Add Description</Modal.Title>
                    </div>
                </Modal.Header>
                <Form onSubmit={handleSubmit2(disApprove)} >
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
                            <Form.Control as="textarea" rows="5" name="disapprove_message" {...register2('disapprove_message', { required: "This field is required" })} autoComplete="off" />

                            {errors2.disapprove_message ?.message && <span className="error">{errors2.disapprove_message ?.message}</span> }
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <Button variant="primary" type="submit" >Submit</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal className="approve-modal" show={descriptionModal} onHide={closeDescriptionModal} centered>
                <Modal.Header closeButton className='justify-content-center'>
                    <div className='text-center'>
                        <Modal.Title className='fs-35 fw-700'>Description</Modal.Title>
                    </div>
                </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3">
                            <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
                            <Form.Control as="textarea" rows="5" name="disapprove_message" value={descriptionValue} autoComplete="off" />

                            {errors2.disapprove_message ?.message && <span className="error">{errors2.disapprove_message ?.message}</span> }
                        </Form.Group>
                    </Modal.Body>
            </Modal>
        </>
    )
}

export default SuperAdminInterpretation;